import { useEffect, useState } from "preact/hooks";

type ThemeMode = 'light' | 'dark' | 'system';

export function ThemeToggle() {
  const [themeMode, setThemeMode] = useState<ThemeMode>('system');
  const [systemIsDark, setSystemIsDark] = useState(false);

  // Initialize theme state based on localStorage or system preference
  useEffect(() => {
    // Check if theme is stored in localStorage
    const storedTheme = localStorage.theme as ThemeMode | undefined;
    
    // If theme is explicitly set in localStorage
    if (storedTheme === 'light' || storedTheme === 'dark') {
      setThemeMode(storedTheme);
    } else {
      // If no explicit theme or it's set to 'system'
      setThemeMode('system');
      // Check system preference
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setSystemIsDark(isDarkMode);
    }

    // Apply theme immediately on component mount
    applyTheme(storedTheme || 'system');

    // Add listener for system theme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      setSystemIsDark(e.matches);
      if (themeMode === 'system') {
        applyThemeClass(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  // Apply theme class based on dark mode state
  const applyThemeClass = (isDark: boolean) => {
    if (isDark) {
      document.documentElement.classList.add('dark');
      document.documentElement.setAttribute('data-theme', 'dark');
      
      // Get the dark background color from theme
      const darkBackground = window.SITE_THEME?.dark?.background || '#1f2937';
      
      // Apply dark theme variables
      document.documentElement.style.setProperty('--color-primary', 
        window.SITE_THEME?.dark?.primary || window.SITE_THEME.primary, 'important');
      document.documentElement.style.setProperty('--color-secondary', 
        window.SITE_THEME?.dark?.secondary || window.SITE_THEME.secondary, 'important');
      document.documentElement.style.setProperty('--color-accent', 
        window.SITE_THEME?.dark?.accent || window.SITE_THEME.accent, 'important');
      document.documentElement.style.setProperty('--color-background', 
        darkBackground, 'important');
      document.documentElement.style.setProperty('--color-text', 
        window.SITE_THEME?.dark?.text || '#f9fafb', 'important');
      
      // Apply background color directly to both html and body elements
      document.documentElement.style.backgroundColor = darkBackground;
      document.body.style.backgroundColor = darkBackground;
      
      // Force a repaint
      void document.body.offsetHeight;
    } else {
      document.documentElement.classList.remove('dark');
      document.documentElement.setAttribute('data-theme', 'light');
      
      // Get the light background color from theme
      const lightBackground = window.SITE_THEME?.light?.background || '#ffffff';
      
      // Apply light theme variables
      document.documentElement.style.setProperty('--color-primary', 
        window.SITE_THEME?.light?.primary || window.SITE_THEME.primary, 'important');
      document.documentElement.style.setProperty('--color-secondary', 
        window.SITE_THEME?.light?.secondary || window.SITE_THEME.secondary, 'important');
      document.documentElement.style.setProperty('--color-accent', 
        window.SITE_THEME?.light?.accent || window.SITE_THEME.accent, 'important');
      document.documentElement.style.setProperty('--color-background', 
        lightBackground, 'important');
      document.documentElement.style.setProperty('--color-text', 
        window.SITE_THEME?.light?.text || '#000000', 'important');
      
      // Apply background color directly to both html and body elements
      document.documentElement.style.backgroundColor = lightBackground;
      document.body.style.backgroundColor = lightBackground;
      
      // Force a repaint
      void document.body.offsetHeight;
    }
  };

  // Apply theme based on mode
  const applyTheme = (mode: ThemeMode) => {
    if (mode === 'system') {
      const systemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      applyThemeClass(systemDark);
      localStorage.removeItem('theme'); // Remove theme from localStorage to use system preference
      console.log('Applied system theme:', systemDark ? 'dark' : 'light');
    } else {
      applyThemeClass(mode === 'dark');
      localStorage.theme = mode;
      console.log('Applied explicit theme:', mode);
    }
    
    // Dispatch a custom event that other components can listen for
    window.dispatchEvent(new CustomEvent('themechange', { 
      detail: { 
        mode, 
        isDark: mode === 'dark' || (mode === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches)
      } 
    }));
  };

  // Handle theme toggle
  const setTheme = (mode: ThemeMode) => {
    setThemeMode(mode);
    applyTheme(mode);
  };

  // Determine if dark mode is active (either explicitly or via system)
  const isDarkActive = themeMode === 'dark' || (themeMode === 'system' && systemIsDark);

  return (
    <div class="relative inline-block text-left">
      <div class="flex items-center space-x-1 rounded-lg p-0.5 bg-gray-100 dark:bg-gray-700">
        {/* Light mode button */}
        <button
          onClick={() => setTheme('light')}
          class={`p-1 rounded-md ${themeMode === 'light' ? 'bg-white shadow-sm' : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'}`}
          aria-label="Light mode"
          title="Light mode"
        >
          <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </button>

        {/* System preference button */}
        <button
          onClick={() => setTheme('system')}
          class={`p-1 rounded-md ${themeMode === 'system' ? 'bg-white dark:bg-gray-600 shadow-sm' : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'}`}
          aria-label="Use system preference"
          title="Use system preference"
        >
          <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </button>

        {/* Dark mode button */}
        <button
          onClick={() => setTheme('dark')}
          class={`p-1 rounded-md ${themeMode === 'dark' ? 'bg-gray-800 shadow-sm' : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'}`}
          aria-label="Dark mode"
          title="Dark mode"
        >
          <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
